<template>
  <BottomSheet name="test-sheet" ref="tips-sheet"> Like </BottomSheet>
</template>

<script>
// import { Analytics, events } from "../services/analytics";
import BottomSheet from "./BottomSheet.vue";

export default {
  name: "TipsBottomSheetLike",
  components: {
    BottomSheet,
  },
  mounted() {
    this.$refs["tips-sheet"].onOpen("test-sheet");
  },
};
</script>

<style lang="scss"></style>
