<template>
  <div class="layout">
    <!-- <NpsTinder v-if="tinderModalShow" @close="toggleTinderModal()" /> -->
    <TipsTinder
      v-if="tinderModalShow"
      v-model="modalResult"
      @close="toggleTinderModal()"
    />
    <TipsBottomSheetLike v-if="openSheet === 'like'" />
    <TipsBottomSheetDislike v-if="openSheet === 'nope'" />
  </div>
</template>

<script>
// import NpsTinder from "./NpsTinder.vue";
import TipsTinder from "./TipsTinder.vue";
import TipsBottomSheetLike from "./TipsBottomSheetLike.vue";
import TipsBottomSheetDislike from "./TipsBottomSheetDislike.vue";

export default {
  name: "Layout",
  components: {
    // NpsTinder,
    TipsTinder,
    TipsBottomSheetLike,
    TipsBottomSheetDislike,
  },
  data() {
    return {
      tinderModalShow: false,
      modalResult: "",
      openSheet: "",
    };
  },
  watch: {
    modalResult(val) {
      setTimeout(() => {
        this.openSheet = val;
      }, 800);
    },
  },
  mounted() {
    this.delayOpenModal();
  },
  methods: {
    toggleTinderModal() {
      this.tinderModalShow = !this.tinderModalShow;
    },
    delayOpenModal() {
      setTimeout(() => {
        this.toggleTinderModal();
      }, 1500);
    },
  },
};
</script>
