<template>
  <modal @close="close()" class="tinder-modal">
    <template v-slot:body>
      <div class="tinder">
        <Tinder
          ref="tinder"
          key-name="id"
          :queue.sync="queue"
          @submit="onSubmit"
          :allowSuper="false"
          v-touch:swipe.left="swipeLeft"
          v-touch:swipe.right="swipeRight"
        >
          <template slot-scope="scope">
            <div
              class="tinder__pic"
              :style="{
                'background-image': `url(${scope.data.id}`,
              }"
            />
          </template>
        </Tinder>
        <div class="tinder__desc" v-show="hideBtns">
          <div class="tinder__name">Алина</div>
          <div class="tinder__waiter">Ваш официант</div>
          <div class="tinder__recommend">Вас хорошо обслужили?</div>
          <div class="tinder__btns">
            <div
              class="button"
              @click="handleDislike"
              :class="{ tinder__selected: activeBtn === 'nope' }"
            >
              <div class="tinder__nope"></div>
            </div>
            <div
              class="button"
              @click="handleLike"
              :class="{ tinder__selected: activeBtn === 'like' }"
            >
              <div class="tinder__like"></div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Tinder from "vue-tinder";
import Modal from "./core/components/Modal.vue";
import { Analytics, events } from "../services/analytics";

export default {
  name: "TipsTinder",
  components: {
    Tinder,
    Modal,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    queue: [],
    hideBtns: true,
    usersChoice: "",
    activeBtn: "",
  }),
  computed: {
    modelValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("change", val);
      },
    },
  },
  watch: {
    hideBtns() {
      setTimeout(() => {
        this.$emit("close");
      }, 500);
    },
  },
  created() {
    this.fetchData(); //TEST -> when data comes from backend
  },
  methods: {
    handleLike() {
      // console.log("HANDLE LIKE");
      Analytics.logEvent(events.TIPS_LIKE_CLICK);
      this.activeBtn = "like";
      setTimeout(() => {
        this.decide("like");
        this.modelValue = "like";
      }, 500);
    },
    handleDislike() {
      // console.log("HANDLE DISLIKE");
      Analytics.logEvent(events.TIPS_DISLIKE_CLICK);
      this.activeBtn = "nope";
      setTimeout(() => {
        this.decide("nope");
        this.modelValue = "nope";
      }, 500);
    },
    swipeLeft() {
      // console.log("SWIPE DISLIKE");
      Analytics.logEvent(events.TIPS_DISLIKE_SWIPE);
      this.modelValue = "nope";
    },
    swipeRight() {
      // console.log("SWIPE LIKE");
      Analytics.logEvent(events.TIPS_LIKE_SWIPE);
      this.modelValue = "like";
    },
    onSubmit(choice) {
      this.hideBtns = false;
      this.usersChoice = choice.type;
    },
    async decide(choice) {
      this.$refs.tinder.decide(choice);
      this.close();
    },
    close() {
      setTimeout(() => {
        this.$emit("close");
      }, 500);
    },
    //TEST -> when data comes from backend
    fetchData() {
      this.axios
        .get(
          "https://api.unsplash.com/photos/?client_id=xsLwZWkxtrGDgmH4UgEgrS7aLAiIfh4De6LeI8A8FLE"
        )
        .then(({ data }) => {
          this.queue.push({ id: data[0].urls.regular });
        });
    },
    openBottomSheet() {
      // console.log("aaa");
      this.$refs["tips-sheet"].openBottomSheet("test-sheet");
    },
  },
};
</script>

<style lang="scss">
#app .vue-tinder {
  position: absolute;
  z-index: 1;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
}
.vue-tinder .tinder-card {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px !important;
}
.tinder {
  &__pic {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  &__desc {
    position: absolute;
    left: 26px;
    right: 26px;
    bottom: 16px;
    z-index: 2;
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__recommend {
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    margin: 0 auto 24px auto;
    font-family: "SFUIDisplayBold", Helvetica, Arial;
  }
  &__name {
    color: #ffffff;
    text-align: center;
    font-style: normal;
    font-weight: 800;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 8px;
    font-family: "SFUIDisplayBold", Helvetica, Arial;
  }
  &__waiter {
    color: #ffffff;
    text-align: center;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    text-align: center;
  }
  &__like,
  &__nope {
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    width: 60px;
    height: 60px;
    transition: all 0.4s ease-in;
    position: relative;
    &:before {
      content: "";
      width: 60px;
      height: 60px;
      background-color: aliceblue;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(0.001, 0.001);
    }
  }
  &__like {
    background-image: url("./../assets/img/like.svg");
  }
  &__nope {
    background-image: url("./../assets/img/nope.svg");
  }
  &__selected {
    position: relative;
    &:before {
      content: "";
      width: 60px;
      height: 60px;
      background-color: aliceblue;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scale(0.001, 0.001);
    }
    outline: 0;
    color: #fff;
    &:before {
      animation: effect_dylan 1s ease-out;
    }
  }
}

@keyframes effect_dylan {
  50% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
  99% {
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
</style>
