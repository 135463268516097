import Vue from "vue";
import App from "./App.vue";
import router from "./router";
// import store from "./store";
import "@/plugins/axios";
import { Analytics } from "./services/analytics";
import Vue2TouchEvents from "vue2-touch-events";
import { RahmetApp } from "./services/rahmet";

Vue.config.productionTip = false;
Vue.use(Vue2TouchEvents);
RahmetApp.setupWebViewApi();
Analytics.init();

if (!window.RahmetApp) {
  window.RahmetApp = {
    requestGeoPermissionStatus() {
      setTimeout(
        () => window.RahmetWebApp.onGeoPermissionStatusDefined("granted"),
        100
      );
    },
  };
}

new Vue({
  router,
  // store,
  render: (h) => h(App),
}).$mount("#app");
