const connect = (callback) => {
  return new Promise((resolve, reject) => {
    try {
      callback(resolve);
    } catch (error) {
      reject(error);
    }
  });
};

export const RahmetApp = {
  authorize() {
    return connect((resolve) => {
      window.RahmetApp.authorize();
      window.RahmetWebApp.onAuthSuccess = (trackId) => resolve(trackId);
    });
  },
  pay(deeplink) {
    return connect((resolve) => {
      window.RahmetApp.pay(deeplink);
      window.RahmetWebApp.onPaySuccess = () => resolve("success");
      window.RahmetWebApp.onNativePayViewClosed = () => resolve("closed");
    });
  },
  requestGeoPermissionStatus() {
    return connect((resolve) => {
      window.RahmetApp.requestGeoPermissionStatus();
      window.RahmetWebApp.onGeoPermissionStatusDefined = (type) =>
        resolve(type);
    });
  },
  applicationBecomeActive(callback) {
    window.RahmetWebApp.didBecomeActive = callback;
  },
  backToRahmetApp() {
    window.RahmetApp.backToRahmetApp();
  },
  setupWebViewApi() {
    window.RahmetWebApp = {
      onAuthSuccess() {},
      onPaySuccess() {},
      onNativePayViewClosed() {},
      onGeoPermissionStatusDefined() {},
      didBecomeActive() {},
    };
  },
};
