export default {
  NPS_LIKE_CLICK: "npstinder_like_click",
  NPS_DISLIKE_CLICK: "npstinder_dislike_click",
  NPS_LIKE_SWIPE: "npstinder_like_swipe",
  NPS_DISLIKE_SWIPE: "npstinder_dislike_swipe",

  TIPS_LIKE_CLICK: "tips-tinder_like_click",
  TIPS_DISLIKE_CLICK: "tips-tinder_dislike_click",
  TIPS_LIKE_SWIPE: "tips-tinder_like_swipe",
  TIPS_DISLIKE_SWIPE: "tips-tinder_dislike_swipe",
};
