<template>
  <div id="app">
    <Layout />
  </div>
</template>

<script>
import Layout from "./components/Layout.vue";

export default {
  name: "App",
  components: {
    Layout,
  },
};
</script>

<style>
@font-face {
  font-family: "SFUIDisplay";
  src: local("SFUIDisplay"),
    url(./assets/fonts/SFUIDisplay-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "SFUIDisplay";
  src: local("SFUIDisplay"),
    url(./assets/fonts/SFUIDisplay-Bold.ttf) format("truetype");
}
body {
  font-family: "SFUIDisplay", Helvetica, Arial;
  overflow: hidden;
}
</style>
