import Vue from "vue";
import VueRouter from "vue-router";
import { api } from "../services/api";
import { RahmetApp } from "../services/rahmet";

Vue.use(VueRouter);

// const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: Home,
//   },
//   {
//     path: "/about",
//     name: "About",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(/* webpackChunkName: "about" */ "../views/About.vue"),
//   },
// ];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.protected) {
    api.auth.validateToken().then((isValid) => {
      if (isValid) {
        next();
      } else {
        RahmetApp.authorize()
          .then((trackId) => api.auth.authByTrackId(trackId))
          .then(() => next())
          .catch(() => {
            if (from.matched.length === 0) {
              next({ name: "Home" });
            } else {
              next(false);
            }
          });
      }
    });
  } else {
    next();
  }
});

export default router;
