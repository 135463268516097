<template>
  <BottomSheet name="test-sheet" ref="tips-sheet">
    <div class="bottom-sheet-dis">
      <divv class="bottom-sheet-dis__title">Что было не так?</divv>
      <div class="bottom-sheet-dis__desc">Опишите ситуацию</div>
      <textarea
        name="bottom-sheet-dis-textarea"
        id="bottom-sheet-dis-textarea"
        rows="1"
        placeholder="Ваш комментарий..."
        type="textarea"
        class="bottom-sheet-dis__textarea"
        oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
      />
      <button class="bottom-sheet-dis__btn">Оставить отзыв</button>
    </div>
  </BottomSheet>
</template>

<script>
// import { Analytics, events } from "../services/analytics";
import BottomSheet from "./BottomSheet.vue";

export default {
  name: "TipsBottomSheetDislike",
  components: {
    BottomSheet,
  },
  mounted() {
    this.$refs["tips-sheet"].onOpen("test-sheet");
  },
};
</script>

<style lang="scss">
.bottom-sheet-dis {
  display: flex;
  flex-direction: column;
  color: #2f2f2f;
  &__title {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 33px;
    margin-top: 23px;
  }
  &__desc {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: -0.24px;
    text-align: center;
    margin-bottom: 22px;
    font-family: "SFUIDisplayBold", Helvetica, Arial;
  }
  &__textarea {
    background: #f2f2f2;
    border-radius: 16px;
    padding: 10px 12px;
    margin-bottom: 14px;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
    max-height: 200px;
    height: 32px;
  }
  &__textarea::placeholder {
    color: #878787;
    letter-spacing: -0.24px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 32px;
  }
  &__btn {
    text-align: center;
    letter-spacing: -0.24px;
    color: #ffffff;
    padding: 10px 12px;
    background: #1886f8;
    border-radius: 16px;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
  }
}
</style>
