import amplitude from "amplitude-js";
// import { api } from "@/services/api";

const amplitudeKey = "d341b7518543b8e1ee33e0f7752bf1f7"; //for TEST (also have for PROD API Key) - Saulet sent
// const cliendId = 12345; //its own for each project -> will come from API
const isDev = process.env.NODE_ENV === "development";

const Analytics = {
  init() {
    amplitude.getInstance().init(amplitudeKey);
  },
  logEvent(event, payload = {}) {
    // const userId = api.getUserId();
    const userId = 9999; //TEST

    let data = {
      eventProperties: {
        // project_id: cliendId,
        project_name: "ChocoTinder",
        ...payload,
      },
      userProperties: {
        // choco_id: userId,
      },
    };

    // логировать событие в режиме разработки
    if (isDev) {
      console.log(event, data);
    }

    amplitude.getInstance().setUserId(userId);
    amplitude.getInstance().logEvent(event, data);
  },
};

export { Analytics };
export * from "./constants";
