import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

// axios.interceptors.request.use(
//   function (config) {
//     // const currentPage = localStorage.getItem('choco_my_list_page')
//     // console.log(config)
//     if (localStorage.getItem('choco_token')) {
//       const token = JSON.parse(localStorage.getItem('choco_token'))
//       config.headers.Authorization = 'Bearer ' + token.access_token
//     }
//     return config
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error)
//   }
// )

Vue.use(VueAxios, axios);
// Vue.axios.defaults.baseURL = process.env.VUE_APP_BASEURL || 'https://testintra2.chocofamily.kz'
// Vue.axios.defaults.baseURL = 'https://cors-anywhere.herokuapp.com/https://testintra2.chocofamily.kz'
// Vue.axios.defaults.baseURL = 'http://testintra2.chocofamily.kz'
// Vue.axios.defaults.baseURL = 'http://localhost:8080'
