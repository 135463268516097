<template>
  <transition name="pop">
    <div class="modal-bg" @click.self="close">
      <div class="my-modal" @click.self="close">
        <section class="modal-body">
          <slot name="body"> </slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "modal",
  mounted: () => (document.body.style.overflow = "hidden"),
  beforeDestroy: () => (document.body.style.overflow = "unset"),
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.modal-fade {
  transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
}
.modal-bg {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .my-modal {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    height: 601px;
    border-radius: 0px;

    .modal-body {
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}
.pop-enter-active,
.pop-leave-active {
  transition: opacity 0.3s linear;
}

.pop-enter,
.pop-leave-to {
  opacity: 0;
}
@media only screen and (max-width: 320px) {
  .modal-bg .my-modal {
    height: calc(100% - 20px);
  }
}
</style>
